// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await axios.get('/api/users/user', { id });
});

export const addUser = createAsyncThunk(
  'appUsers/addUser',
  async (user, { dispatch, getState }) => {
    await axios.post('/apps/users/add-user', user);
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return user;
  }
);

export const deleteUser = createAsyncThunk(
  'appUsers/deleteUser',
  async (id, { dispatch, getState }) => {
    await axios.delete('/apps/users/delete', { id });
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return id;
  }
);

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: (builder) => {
  }
});

export default appUsersSlice.reducer;
