import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as moment from 'moment';
import BaseApi from '@src/API/BaseApi';
import { filterBuilder } from '@helpers/Actions';
import { END_OF_CALENDAR, START_OF_CALENDAR } from '../Calendar/constants';

export const fetchEvents = createAsyncThunk('employeeTimesheet/fetchEvents', async (filters, {getState}) => {
  const {start_date, end_date } = filters;

  const new_params = {
     filters: {
      start_date: start_date ?? getState().employeeTimesheet?.validRange?.start, 
      end_date: end_date ?? getState().employeeTimesheet?.validRange?.end, 
    }
  };
  const queryStr = filterBuilder(new_params);
  const response = await baseApi.get(`/api/employee-timesheet?${queryStr}`);

  return {
    events: response?.data?.data?.list || [],
    sideBar: response?.data?.data?.sideBar || [],
    total_by_status: response?.data?.data?.total_by_status || [],
    params: new_params
  };
});

export const fetchFilters = createAsyncThunk('employeeTimesheet/fetchFilters', async (_) => {
  const response = await baseApi.get(`/system-configs/timesheet_filters`);
  return response?.data?.data;
});

export const addEvent = createAsyncThunk(
  'employeeTimesheet/addEvent',
  async (data) => {
    const response = await baseApi.post(`/api/employee-timesheet`, data);
    return response;
  }
);

export const updateEvent = createAsyncThunk(
  'employeeTimesheet/updateEvent',
  async ({id, data}) => {
    const response = await baseApi.put(`/api/employee-timesheet/${id}`, data);
    return response;
  }
);

export const removeEvent = createAsyncThunk('employeeTimesheet/removeEvent', async (id) => {
  const response = await baseApi.delete(`/api/employee-timesheet/${id}`);
  return response;
});

export const sendEvent = createAsyncThunk('employeeTimesheet/sendEvent', async (id) => {
  const response = await baseApi.post(`/api/employee-timesheet/${id}/send`);
  return response;
});

//TODO: get all calendar configs (filters, options, weekends and limitations)
export const updateFilter = createAsyncThunk(
  'employeeTimesheet/updateFilter',
  async (id, { getState }) => {
    let filters = getState().employeeTimesheet.checkedFilters;
    if (filters.includes(id)) {
      filters = filters.filter(i => i !== id);
    } else {
      filters = [...filters, id];
    }
    return filters;
}
);


export const updateAllFilters = createAsyncThunk(
  'employeeTimesheet/updateAllFilters',
  async ({ value }, {getState}) => {
    const allfilters = getState().employeeTimesheet.allfilters;
    return value ? allfilters : [];
  }
);

const getOptions = (filters) => {
  let resultOptions = [];
  filters.map(category => {
    let options = [], group = {};
    const { name, subCategory } = category;
    //
    subCategory?.map(subCat => {
        options.push({
            label: subCat.name,
            value: subCat.id,
            options: subCat.options,
            calendar: subCat.name,
            title: subCat.name
          })
    });
    group.label = name;
    group.options = options;
    //
    resultOptions.push(group);
  });
  return resultOptions;
}

const dataMapper = (data) => {

  return data?.map(item => {
    return {
      ...item,
      start: moment(item.start_date).toDate(),
      end: moment(item.end_date).toDate(),
      start_time: item.start_time,
      end_time: item.end_time,
    }
  })
}


export const employeeTimesheetSlice = createSlice({
  name: 'employeeTimesheet',
  initialState: {
    params:{
      filters: {
        start_date: undefined,
        end_date: undefined
      }
    },
    allEvents:[],
    events: [],
    sideBar:[],
    filters: [],
    options: [],
    selectedEvent: {},
    checkedFilters: [],
    allfilters: [],
    weekends: [1, 5],
    officialVacations: [],
    refetchConfig: true,
    isOpen: false,
    datesRangeType: 'all-time',
    status: '',
    validRange: {
      start: START_OF_CALENDAR,
      end: END_OF_CALENDAR
    }
  },
  reducers: {
    selectEvent: (state, action) => {
      const { event } = action.payload;
      state.selectedEvent = event;
    },
    handleAddSidebar: (state, action) => {
      state.isOpen = !state.isOpen;
      //reset selected event
      if (!state.isOpen) {
        state.selectedEvent = {};
      }
    },
    updateStatus: (state, action) => {
      const status = action.payload;
      let events = state.allEvents;
      if (status) {
        events = events.filter(ev => (status === ev.status));
      }
      events = events.filter(ev => (state.checkedFilters?.includes(ev.timesheet_sc_id)));
      //update state
      state.events = events;
      state.status = status;
    },
    updateValidRange: (state, action) => {
      const {start, end, datesRangeType} = action.payload;
      state.validRange = {
        start,
        end
      };
      state.datesRangeType = datesRangeType
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const { params, sideBar, events: data, total_by_status: totalByStatus} = action.payload;
        const filters = state.checkedFilters;
        const status = state.status;
        //map and flat the events
        const allEvents = dataMapper(data);
        //handle filtered events
        let events = allEvents;
        if (status !== '') {
          events = events.filter(ev => (status === ev.status));
        }
        if (filters && filters?.length) {
          events = events.filter(ev => (filters?.includes(ev.timesheet_sc_id)));
        }
        //update state
        state.events = events
        state.sideBar = sideBar;
        state.allEvents = allEvents;
        state.totalByStatus = totalByStatus;
        state.params = params;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
      
        //const { value } = action.payload;
        //state.events = value;
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.filters = action.payload;
        state.options = getOptions(action.payload);
        state.refetchConfig = false;
        //
        const filtersArr = action.payload?.map(cat => cat.subCategory.map(subCat => subCat.id)).flat();
        state.allfilters = filtersArr;
        state.checkedFilters = filtersArr;
      })
      .addCase(fetchFilters.rejected, (state, action) => {
        state.refetchConfig = true;
        //todo: handle error
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        const filters = action.payload;
        const status = state.status;
        let events = state.allEvents;//get all events
        events = events.filter(ev => (filters?.includes(ev.timesheet_sc_id)));
        if (status !== "") {
          events = events.filter(ev => (status === ev.status));
        }
        //update state
        state.events = events;
        state.checkedFilters = filters
      }) 
      .addCase(updateFilter.rejected, (state, action) => {
      
      }) 
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const filters = action.payload;
        const status = state.status;
        
        let events = state.allEvents;//get all events
        events = events.filter(ev => (filters?.includes(ev.timesheet_sc_id)));
        //handle filtred status
        if (status !== '') {
          events = events.filter(ev => (status === ev.status));
        }
        //update state
        state.checkedFilters = filters;
        state.events = events;
      });
  }
});

export const { selectEvent, handleAddSidebar, updateStatus, updateValidRange } = employeeTimesheetSlice.actions;

export default employeeTimesheetSlice.reducer;
