import NiceModal from '@ebay/nice-modal-react';
import { useDispatch, useSelector } from 'react-redux';

export const ModalsProvider = ({ children }) => {
  const modals = useSelector((s) => s.nicemodals);
  const dispatch = useDispatch();
  
  return (
    <NiceModal.Provider modals={modals} dispatch={dispatch}>
      {children}
    </NiceModal.Provider>
  );
};
