import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BaseApi from "@baseApi";
import { links } from '@src/API/links';
import axios from 'axios';

export const getOptions = createAsyncThunk('systemLists/getData', async (params) => {
  //type used to get the endpoint uri
  //queykey used to save response data inside the redux store
  const { searchParams, type, queryKey } = params;
  const uri = links[type];

  if (!uri || !type) {
    throw new Error('Invalid Request Parameters');
  }

  const searchQuery = (searchParams && searchParams?.size) ? `?${searchParams}` : '';
  const data = await BaseApi().get(`${uri}${searchQuery}`);

  return {
    data: data,
    queryKey: queryKey || type
  };
});



export const systemListsSlice = createSlice({
  name: 'systemLists',
  initialState: {
    data: {},
    selectedValues: {},
    selectedValuesId: [],
  },
  reducers: {
    invalidateList: (state, action) => {
      const type = action.payload;
      state.data = { ...state.data, [type]: [] };
      return state;
    },
    invalidateAllLists: (state, action) => {
      state.data = [];
      return state;
    },
    setSelectedValues: (state, action) => {
      const { queryPath, name, values } = action?.payload;
      if (values && Array.isArray(values)) {
        state.selectedValues = {
          ...state.selectedValues,
          [queryPath]: values
        }
      }
      return state;
    },
    addSelectedValues: (state, action) => {
      const { queryPath, value } = action?.payload;
      if (!value) {
        return state;
      }
      //get old selected values
      const selectedValues = state.selectedValues ? state.selectedValues[queryPath] : [];
      //check old values and update it
      if (selectedValues && selectedValues.length) {
        state.selectedValues[queryPath] = [...new Set([...selectedValues, value])];
      } else {
        state.selectedValues = {
          ...state.selectedValues,
          [queryPath]: [value]
        };
      }
      //
      return state;
    },
    setFocused: (state, action) => {
      state.focused = action.payload;
    },
    getFocused: (state, action) => {
      return parseInt(state?.focused || 0);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getOptions.fulfilled, (state, action) => {
      const { queryKey, data } = action.payload;
      state.data = { ...state.data, [queryKey]: data }
    }).addCase(getOptions.rejected, (state, action) => {

    })
  }
});

export const { invalidateList, invalidateAllLists, addSelectedValues, setSelectedValues, setFocused, getFocused } = systemListsSlice.actions;
export default systemListsSlice.reducer;