import { store } from "@src/redux/store";

export const getBaseUrl = () => {
  const {isLogin = false, company = {}} = store.getState().company || {};
  const {REACT_APP_MAIN_DOMAIN, REACT_APP_BASE_API_PROTOCOL, REACT_APP_BASE_API_DOMAIN} = process.env;
  //
  if (isLogin && company.domain) {
    return `${REACT_APP_BASE_API_PROTOCOL}${company.domain}.${REACT_APP_MAIN_DOMAIN}`;
  }
  return `${REACT_APP_BASE_API_PROTOCOL}${REACT_APP_BASE_API_DOMAIN}`;
};

export const getUserToken = () => {
  const {access_token} = store.getState().loginUser;
  return access_token;
};
export const getRefreshToken = () => {
  const {refresh_token} = store.getState().loginUser;
  return refresh_token;
};