// ** Reducers Imports
import NiceModal from "@ebay/nice-modal-react";
import navbar from "./navbar";
import layout from "./layout";
import sidebar from "./sidebar";

import appUsers from "@dashboard/User/Profile/ViewProfile/store";
import dynamicTabs from "@components/DynamicTabs/store";
//Company reducers
import company from "@views/Auth/Company/Login/store";
import salesMeeting from "@companyAuth/ArrangeSalesMeeting/store";

//User Reducers
import loginUser from "@userAuth/Login/store";

import helpers from "./actions/Helpers";
import toastify from "./actions/Notify";

import modalReducer from "@components/Modals/store";

import systemTypes from "@dashboard/WorkSector/SystemConfigurations/DropdownLists/store";
import systemLists from "@components/ReduxComponents/SingleSelect/store";
import employeeTimesheet from "@dashboard/PersonalSector/TimeSheet/store";
import appDescussions from "@dashboard/Chat/store";

//Work Sector
import companyVacationsCalendar from "@dashboard/WorkSector/SystemConfigurations/CompanyOfficialVacations/store";
import companySettings from "@dashboard/WorkSector/SystemConfigurations/CompanyProfile/store";
import coreForm from "@components/Form/store";

const nicemodals = NiceModal.reducer;

const rootReducer = {
  nicemodals,
  toastify,
  helpers,
  navbar,
  layout,
  sidebar,
  company,
  salesMeeting,
  loginUser,
  modalReducer,
  appDescussions,
  appUsers,
  employeeTimesheet,
  companyVacationsCalendar,
  //ecommerce,
  systemTypes,
  systemLists,
  dynamicTabs,
  companySettings,
  coreForm
};

export default rootReducer;
