// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRandomKey, hanleResponseErrors } from '@helpers/Actions';
import BaseApi from "@baseApi";
import { ls } from '@src/Services/Helpers/LocalStorageEnc';
import CrudApi from '@src/API/CrudApi';
import { domNotify } from '@src/@core/components/Notify/DomNotify';

export const handleCompanyLogin = createAsyncThunk(
  'company/login',
  async ({ company_id }, { fulfillWithValue, rejectWithValue }) => {
    const response = await BaseApi().post('/api/company/login', { company_id: `CO-${company_id}` })
    .then((res) => res)
    .catch((e) => hanleResponseErrors(e));
    return response.status === 200 ? fulfillWithValue(response?.data?.data) : rejectWithValue(response?.data);
  }
);

export const handleCompanyLogout = createAsyncThunk(
  'company/logout',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    //TODO: handle logout from server
    return {};
  }
);


export const getCompanyProfile = createAsyncThunk(
  'company/getProfile',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await BaseApi().get('/api/company/profile')
    .then((res) => res)
    .catch((e) => hanleResponseErrors(e));
    return response.status === 200 ? fulfillWithValue(response?.data?.data) : rejectWithValue(response?.data);
  }
);

export const updateCompanyProfile = createAsyncThunk(
  'company/updateProfile',
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const response = await CrudApi().post('/api/company/profile/update',  {...payload})
    .then((res) => {
      return {
        status: res.status,
        response: res?.data?.data,
        payload: payload
      }
    })
    .catch((e) => hanleResponseErrors(e));
    return response.status === 200 ? fulfillWithValue(response) : rejectWithValue(response);
  }
);

  const resetData=(state)=>{
    state.company = {};
    state.company.refreshedKey = null;
    state.isLogin = false;
    state.updatedAt = null;
    localStorage.clear();
    ls.clear();
  }

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: {},
    isLogin: false,
  },
  reducers: {
    resetCompanyData: (state) => {
      resetData(state)
    },
    updateCompany: (state, action) => {
      const data = action.payload || {};
      state.company = data;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCompanyLogin.fulfilled, (state, action) => {
        const company = action?.payload;
        company.refreshedKey = getRandomKey();
        state.company = company;
        state.isLogin = true;
      })
      .addCase(handleCompanyLogin.rejected, (state, action ) => {
        resetData(state);
      })
      .addCase(handleCompanyLogout.fulfilled, (state, action) => {
        resetData(state);
      })
      .addCase(handleCompanyLogout.rejected, (state, action ) => {
        resetData(state);
      })
      .addCase(getCompanyProfile.fulfilled, (state, action) => {
        let company = action?.payload.item;
        company.refreshedKey = getRandomKey();
        state.company = company;
      })
      .addCase(getCompanyProfile.rejected, (state, action ) => {
        //TODO: will handle errors
      })
      .addCase(updateCompanyProfile.fulfilled, (state, action) => {
        domNotify({
          type: "success",
          title: "Updated Successfully",
          text: "Your Company Account Updated Successfully !",
        });
        let company = action?.payload?.response;
        company.refreshedKey = getRandomKey();
        //console.log(company)
        state.company = company;
      })
      .addCase(updateCompanyProfile.rejected, (state, action ) => {
        //TODO: will handle errors
        domNotify({
          type: "error",
          title: "Error Update",
          text: "Profile Can Not Updated.",
        });
      });
  }
});

export const { resetCompanyData , updateCompany } = companySlice.actions;
export default companySlice.reducer;
