import React from 'react'
import './index.css'

const ReportTemplate = () => {
    const styles = {
		page: {
			pageBreakAfter: 'always',
		},

		columnLayout: {
			display: 'flex',
			justifyContent: 'space-between',
			margin: '3px 0 5px 0',
			gap: '2px',
		},

		column: {
			display: 'flex',
			flexDirection: 'column',
		},

		spacer2: {
			height: '2px',
		},

		fullWidth: {
			width: '100%',
		},

		marginb0: {
			marginBottom: 0,
		},
	};
	return (
		<html style={{fontFamily: "Vazirmatn"}} >
			<head>
				<meta charSet='utf-8' />
			</head>
			<body>
				<div>
					<p style={{fontFamily: "Vazirmatn"}}>العربية</p>
					<p>English</p>
					<p>Ich heiße Tschüss</p>
				</div>
			</body>
			<footer>
				hello world
			</footer>
		</html>
  )
}

export default ReportTemplate