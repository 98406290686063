import { createMongoAbility, AbilityBuilder } from "@casl/ability";
import { store } from "@src/redux/store";

const ability = new createMongoAbility();

store.subscribe(() => {
  const { permissions = [] } = store.getState().loginUser;
  const rules = defineRules(permissions);
  ability.update(rules);
});

const defineRules = (permissions) => {
  const { can, rules } = new AbilityBuilder();

  [...new Set(permissions)].forEach((p) => {
    const per = p.split("_");
    can(per[0], per[1]);
  });

  return rules;
};

export default ability;
