export const filters = [
  { label: 'National Vacations', className: 'form-check-success mb-1' },
  { label: 'Religious Vacations', className: 'form-check-success mb-1' },
  { label: 'Special Vacations', className: 'form-check-success mb-1' }
];

const getDefaultEvents = () =>
  filters.map((filter) => filter.label.toLocaleLowerCase().split(' ').join('-'));
const getOptions = () =>
  filters.map((o, i) => {
    return {
      ...o,
      //color: "secondary",
      value: i + 1,
      calendar: o.label.toLowerCase().split(' ').join('-'),
      title: o.label
    };
  });
export const defaultEvents = getDefaultEvents();
export const options = getOptions();
