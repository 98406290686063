// ** React Imports
import React, { Suspense, lazy } from "react";
import { createRoot } from 'react-dom/client';
//import * as serviceWorker from "./serviceWorker";
import { Provider as ReduxProvider } from "react-redux";
import { queryClient } from "@providers/QueryClient";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { compress, decompress } from "lz-string";
import { ModalsProvider } from "@providers/ModalProvider";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { registerPdfFonts } from "./utility/fonts";

// ** Photo Preview
import { PhotoProvider } from "react-photo-view";

// ** Toaster For All
import Toaster from "@components/Notify";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "@components/spinner/Fallback-spinner";

// ** Ripple Button for reactstrap library
import "@components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";
// ** Photo Preview styles
import "react-photo-view/dist/react-photo-view.css";
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
// ** Overrided core styles
import "./assets/scss/style.scss";


//REGISTER PDF ARABIC FONT
registerPdfFonts();

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  serialize: (data) => compress(JSON.stringify(data)),
  deserialize: (data) => JSON.parse(decompress(data))
});

//const persistedQueries = ['countries', 'cities', 'areas', 'currencies'];

const persistOptions = {
  persister,
  hydrateOptions: {},
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey, ...options }) => {
      return options?.meta?.offline && true;
    }
  },
  maxAge: Infinity
};

// ** Lazy load app
const App = lazy(() => import("./App"));
const container = document.getElementById('root');
const root = createRoot(container);
// ** Render the app
root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AbilityContext.Provider value={ability}>
        <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
          <Suspense fallback={<Spinner />}>
            <PhotoProvider loop={false}>
            <ThemeContext>
                <ModalsProvider />
                <App />
                <ToastContainer newestOnTop>
                  <Toaster />
                </ToastContainer>
              </ThemeContext>
            </PhotoProvider>
          </Suspense>
        </PersistQueryClientProvider>
      </AbilityContext.Provider>
    </PersistGate>
  </ReduxProvider>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
