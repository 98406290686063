export const links = Object.freeze({
  "countries": "api/list/countries",
  "company_branches": "api/list/branches",
  "expense-types": "/api/list/expense-types",
  "taxes-types": "/api/list/taxes-types",
  "payment-methods": "/api/list/payment-methods",
  "payment-terms": "/api/list/payment-terms",
  "purchase-order-types": "/api/list/purchase-order-types",
  "departments": "/api/list/departments",
  "assets-categories": "/api/list/assets-categories",
  "currencies": "/api/list/currencies",
  "custody-senders": "/api/list/custody-senders",
  //
  "roles": "/api/list/roles",
  "assets": "/api/list/assets",
  "purchases-invoices": "/api/list/purchases-invoices",
  "sales-invoices": "/api/list/sales-invoices",
  "clients-list": "/api/list/clients",
  "vendors-list": "/api/list/vendors",
  "employees-list": "/api/list/employees",
  //
  "tenders": "/api/list/tenders",
  "client-orders": "/api/list/client-orders",
  "vendor-orders": "/api/list/vendor-orders",
  "client-tenders": "/api/list/client-tenders",
  "timesheet-categories": "/api/list/timesheet-categories",
  "product-categories": "/api/list/product-categories",
  "service-categories": "/api/list/service-categories",
  "measurement-units": "/api/list/measurement-units",
  "company-sectors": "/api/list/company-sectors",
});